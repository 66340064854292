import React from "react"

export default class StatesListHome extends React.Component 
{    
    constructor(props)
    {
        super(props);

        this.env = this.props.env;
        this.states = this.props.states;           
        this.code = this.props.code;
        this.codes = this.props.codes;
        this.page = this.props.page !== undefined ? ' '+this.props.page : '';
        this.code = this.code === 'main' ? (this.env.site === 'dumpster' ? 'dumpster' : 'demolition-contractors') : this.code;
        this.state =
        {
            isPress: true
        }
    }

    componentDidMount(){
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        if(!params.about) {
            this.setState({isPress: false}) 
        }
    }

    getUrl = (slug, state) =>
    {        
        let path; 
        
        this.codes.map(code =>
        {                         
            if(code.code === this.code)
            {
                switch (slug) 
                {
                    case 'district-of-columbia':							
                    path = `/district-of-columbia/${code.code}-district-of-columbia-dc`;
                        break;
                
                    default:

                        switch (code.code) 
                        {
                            case 'dumpster': case 'demolition-contractors':
                                path = `/${slug}/index.html`;
                                break;

                            case 'comercial-trash-service':
                                path = `/${slug}/${code.paths.code}/index.html`;
                                break; 
                                
                            default:
                                path = `/${slug}/${code.code}/index.html`;
                            break;
                        }

                        break;
                }
            }
        })       
        
        return <a href={path}>{state.name}</a>
    }

    pickTitle = () =>
    {       
        if (this.props.title !== undefined)
        {
            return this.props.title;
        }                
        
        let title = this.env.site === 'dumpster' ? "Browse {service} Services by State" : "Browse {service} Contractors by State";      

        this.codes.map(code =>
        {
            if(code.code == this.code)
            {
                title = title.replace('{service}', code.name);
            }
        }) 

        return title;
    }

    render() 
    {
        return (
            <section id="state-list-section" className={"section states-list "+this.env.site+this.page}>
                <div className="container" style={{borderBottom: '1px solid #646464'}}>
                    <div className="row">
                        <div onClick={() => this.setState({isPress: !this.state.isPress})} className="col-12 textContainer">
                            <h3>{this.pickTitle()}</h3>
                            { this.state.isPress ?
                            <span className="material-icons">expand_less</span> :
                            <span className="material-icons">expand_more</span>
                            }
                        </div>
                    </div>
                    { this.state.isPress &&
                    <ul className="all-states">
                    {                       
                        this.states.map((state, index) => 
                        {                                                       
                            return (
                                <li key={index}>
                                    {this.getUrl(state.slug, state)}        
                                </li>
                            )
                        })
                    }
                    </ul>
                    }
                </div>
            </section>
        )
    }
}