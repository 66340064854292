import React, { useState, lazy, Suspense } from "react";
import Modal from 'react-modal';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

const OnDeffImplementation = lazy(() => import('./on-deff-implementation'))

const PopularProjectsNearYou = ({popular_projects, env}) => {
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [ondeffCode, setOndeffCode] = useState('')

    let initialValuePoolLimit = 0;
    if (typeof window !== 'undefined') {
        const params = new URLSearchParams(window.location.search)
        if (params.get("test") == "true") {
            initialValuePoolLimit = 1;
        }
    }
    const [limitPoolSize, setPoolTest] = useState(initialValuePoolLimit)

    const [modalStyles, setModalStyles] = useState(
        {
            content:
            {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                borderRadius: '10px',
                border: 'none',
                backgroundColor: '#e8e8e8',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                padding: '0px 20px',
                minHeight: 'auto',
                width: '600px'
            }
        })

   
    return (
        <div className="popularProjectsNearYou container">
            <h2>Popular projects near you</h2>
            <ul className="popularProjects">
                {popular_projects?.map((project, index) => 
                <li key={index} className='eachProject'>
                    <a href="#" onClick={(e) => {e.preventDefault(); setShowModal(!showModal); setOndeffCode(project.ondeffCode)}} >
                        <div className="image">
                            <img src={project.icon.path} alt={project.icon.alt} />
                        </div>
                        <div className="text">
                            <p>{project.title}</p>
                        </div>
                    </a>
                </li>
                )}
            </ul>
            <Modal
                id={1}
                isOpen={showModal}
                style={modalStyles}
                contentLabel="Get a Quote!"
                shouldCloseOnOverlayClick={false}
                preventScroll={true}
                ariaHideApp={false}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="modal-header">
                    <button type="button" className="close closermodal" onClick={() => setShowModal(!showModal)} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div id="quote-steps-modal-library">
                  <Suspense fallback={<h1>Loading…</h1>}>
                    <OnDeffImplementation
                        initialData={[{key: 'limit_poolsize', value: limitPoolSize }]}
                        formCode={ondeffCode}
                        loadingFunction={setLoading}>
                    </OnDeffImplementation>
                  </Suspense>
                </div>
                <div id="temp"></div>
            </Modal>
        </div>
    )
}

export default PopularProjectsNearYou