import React from "react"
import Cookies from 'js-cookie'

export default class QuoteStepsModal extends React.Component {
    constructor(props) {
        super(props)

        this.state =
        {
            error: '',
            redirect: '',
            proid: 0,
            steps: ['message'],
            code: 'comercial-trash-service',
            zipcode: (Cookies.get('zipcode') !== undefined ? Cookies.get('zipcode') : '')
        }

        this.modal_project_types = this.props.modal_project_types
        this.service = this.props.env.service !== undefined ? this.props.env.service : 'https://bookable-api-dev.azurewebsites.net'
        this.service_type = 'comercial-trash-service'

        this.state.steps.push('zipcode',)
        this.state.steps.push('info') //always last (got submit btn)
        this.state.steps.push('confirm') //keep after info
        this.state.steps.push('ok') //keep after info

    }



    onZipChange = (event) => {
        this.setState({
            'zipcode': event.target.value
        })
    }



    onlyZipStep = () => {
        return (

            <div key={10} className="steps onlyzip">
                <div className="step step-zip the-step-zip">
                    <div className="step-only-zip">
                        <div className="modal-body">
                            <h3>Where do you need service?</h3>
                            <input name="step-zipcode" type="text" onChange={this.onZipChange} placeholder="Zip code" value={this.state.zipcode} maxLength="5" />
                            <div className="onError">{this.state.error}</div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary btn-preloader">Search</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    addStep = (step) => {
        let step_html

        switch (step) {

            case 'onlyzip':
                step_html = this.onlyZipStep()
                break


            default:
                break
        }

        return step_html
    }

    // -------- -NAVIGATION-

    back = (name, e) => {
        e.preventDefault()

        this.resetError()

        const allsteps = this.state.steps
        const index = this.state.steps.findIndex(step => step === name)

        let step_current_name = name + 'step'
        let step_back_name = allsteps[index - 1] + 'step'
        let progress = index - 1

        const field_zipcode = document.getElementsByName('step-zipcode')

        if (field_zipcode.length === 0) {
            if (allsteps[index - 1] === 'zipcode') {
                step_current_name = allsteps[index] + 'step'
                step_back_name = allsteps[index - 2] + 'step'
                progress = index - 2
            }
        }

        const current = document.getElementsByClassName(step_current_name)
        current[0].style.display = 'none'

        const next = document.getElementsByClassName(step_back_name)
        next[0].style.display = 'block'

  
    }

    goto = (name) => {
        this.resetError()

        const allsteps = this.state.steps
        const index = allsteps.findIndex(step => step === name)

        let step_current_name = name + 'step'

        if (name === 'ok') step_current_name = 'infostep'

        let step_next_name = allsteps[index + 1] + 'step'

        if (name === 'ok') step_next_name = 'okstep'


        const current = document.getElementsByClassName(step_current_name)
        if(current[0]) current[0].style.display = 'none'

        const next = document.getElementsByClassName(step_next_name)
        if(next[0]) next[0].style.display = 'block'

    }

    next = (name, e) => {
        if (e !== null) e.preventDefault()
        this.resetError()

        const allsteps = this.state.steps
        const index = allsteps.findIndex(step => step === name)

        let step_current_name = name + 'step'
        let step_next_name = allsteps[index + 1] + 'step'
        let progress = index + 1

        const field_zipcode = document.getElementsByName('step-zipcode')

        if (field_zipcode.length === 0) {
            if (allsteps[index + 1] === 'zipcode') {
                step_current_name = allsteps[index] + 'step'
                step_next_name = allsteps[index + 2] + 'step'
                progress = index + 2
            }
        }

        if (!this.validateStep(name)) return

        const current = document.getElementsByClassName(step_current_name)
        current[0].style.display = 'none'

        const next = document.getElementsByClassName(step_next_name)
        next[0].style.display = 'block'

    }

    // -------- -ERRORS-

    onError = (message) => {
        this.setState({ error: message })
    }

    resetError = () => {
        this.setState({ error: '' })
    }

    // -------- -VALIDATE-
    validateStep = (stepname) => {
        switch (stepname) {
            case 'zipcode':

                const zip = this.state.zipcode
                const regex = new RegExp('^\\d{5}$')

                if (!regex.test(zip)) {
                    this.onError('The zip code must have 5 number.')
                    return false
                }

                this.getKppUrl(zip, true, 'zipcode')
                break


            default: break
        }

        return true
    }

    // -------- -FUNCTIONS-

    resetModal = () => {

        const steps = document.getElementsByClassName('step')
        const onlyzip = document.getElementsByClassName('the-step-zip')
        const form = document.getElementById('modalForm')

        this.props.closeModal()

        const that = this

        setTimeout(function () {

            form.reset()

            onlyzip[0].style.display = 'block'

        }, 500)
    }

    confirmClose = (e) => {
        this.resetModal()
        return
        
    }

    formDataToObject = (formData) => {
        let object = {}
        formData.forEach((value, key) => {
            switch (key) {
                case 'step-zipcode':
                    key = 'zip_code'
                    break

                default:
                    break
            }

            if (!Reflect.has(object, key)) {
                object[key] = value
                return
            }

            if (!Array.isArray(object[key])) {
                object[key] = [object[key]]
            }

            object[key].push(value)
        })
        return object
    }

    formData = () => {
        let form = document.getElementById('modalForm')
        const formdata = new FormData(form)
        const data = this.formDataToObject(formdata)

        return data
    }

    handleSubmit = event => {
        event.preventDefault()

        const form_data = this.formData()
        
        if (!this.validateStep('zipcode')) return

        this.preloader('show')

        // this.redirect()

        return

    }

    preloader = (action) => {
        const button = document.getElementsByClassName('btn-preloader')

        if (action === 'show') {
            button[0].disabled = true
            button[0].className = button[0].className + ' show-preloader'

            return
        }

        button[0].disabled = false
        button[0].className = button[0].className.replace(' show-preloader', '')
    }

    preloadernext = (action) => {
        const button = document.getElementsByClassName('nextzip')

        if (action === 'show') {
            button[0].disabled = true
            button[0].className = button[0].className + ' show-preloader'
            return
        }

        button[0].disabled = false
        button[0].className = button[0].className.replace(' show-preloader', '')
        button[0].innerText = 'Next >'
    }

    showOk = () => {
        this.goto('ok')
    }

    getKppUrl = async (zip, redirect, goto = null) => {
        const code = this.state.code === 'demolition' ? 'demolition-contractors' : this.state.code
        const endpoint = '/api/v1/kpp/service_types/' + code + '/zip_codes/' + zip
        const url = this.service + endpoint
        const res = await fetch(url,
            {
                method: 'get',
            })
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                this.preloader('hide')
                return response
            })
            .catch((error) => {
                console.log(error)
            })
        if (Array.isArray(res)) {
            //this.setState({'error': 'Zip not Found.'})
            this.setState({ 'redirect': 'redirect/no-zip-found' })
            if (redirect) this.redirect()
            return
        }

        this.cookies(zip)

        this.setState({ 'redirect': res })
        
        if (redirect) this.redirect()
    
        // if (goto !== null) this.goto(goto)
    }

    cookies = (zip) => {
        Cookies.set('zipcode', zip)
    }

    redirect = () => {
        window.location.replace('/' + this.state.redirect)
        // if(typeof this.state.redirect !== 'object' &&  !Array.isArray(this.state.redirect)) window.location.replace(this.state.redirect)                   
    }


    render() {
        return (
            <div key="qsm-1"  id="quote-steps-modal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="quote-steps-modal-label" aria-hidden="true">
                <div key="qsm-2" className="modal-dialog" role="document">
                    <div key="qsm-3" className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close closermodal" onClick={this.confirmClose} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>

                            
                        </div>
                        <form id="modalForm" onSubmit={this.handleSubmit} key="qsm-4">
                            {this.onlyZipStep()}
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}