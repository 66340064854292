import React from 'react';
import Cookies from 'js-cookie' //https://www.npmjs.com/package/js-cookie

export default class ZipFinderHomeSmall extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            zip: ''
        }

        this.code = this.props.code;
        this.service = this.props.env.service !== undefined ? this.props.env.service : 'https://bookable-api-dev.azurewebsites.net';
        this.env = this.props.env;
    }

    handleSubmit = event => {
        event.preventDefault();

        const zip = this.state.zip;

        if (!this.validateValue(zip)) {
            this.showError('Zip Code is required');
            return;
        }

        if (!this.validateNumber(zip)) {
            this.showError('The zip code must have 5 number');
            return;
        }

        this.getUrl(zip, true);
    }

    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    validateValue = value => {
        return value !== '' ? true : false;
    }

    validateNumber = value => {
        const regex = new RegExp('^\\d{5}$');
        return regex.test(value);
    }

    getUrl = (zip, redirect) => {
        let endpoint = '/api/v1/kpp/service_types/' + this.code + '/zip_codes/' + zip;
        const url = this.service + endpoint;

        fetch(url,
            {
                method: 'get',
            })
            .then((response) => {
                if (response.status === 400) {
                    return '/redirect/no-zip-found';
                }

                return response.json();
            })
            .then((response) => {
                this.cookies(zip);

                this.setState({ 'redirect': response });

                if (redirect) {
                    this.redirect(response);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    cookies = (zip) => {
        Cookies.set('zipcode', zip);
    }

    showError = text => {
        const input = document.getElementById(this.props.id);

        input.value = '';
        input.placeholder = text;
    }

    redirect = url => {
        if (typeof url !== 'object' && !Array.isArray(url)) {
            window.location.replace('/' + url);
        } else {
            window.location.replace('/redirect/no-zip-found');
        }
    }

    render() {
        return (
            <div className="zip-finder-small mb-5">
                        <div className="card">
                            <form onSubmit={this.handleSubmit}>
                                <input
                                    id={this.props.id}
                                    className="form-control"
                                    type="text"
                                    name="zip"
                                    value={this.state.zip}
                                    placeholder="Enter Zip Code"
                                    onChange={this.handleInputChange}
                                    maxLength="5"
                                />
                                <input className="btn btn-primary" type="submit" value={this.props.button} />
                            </form>
                        </div>
                    </div>
        )
    }
}