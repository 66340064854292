import React, { useEffect, useState } from "react"

const ProjectTypesList = (props) => {
    const [isPress, setIsPress] = useState(true);
    
    useEffect(() => {
        setIsPress(false);
    }, [])

    return (
        <section className={"section states-list"} style={{paddingTop: '0px', backgroundColor: props.env?.site === 'demolition' ? '#FFFFFF' : '#e8e8e8'}}>
            <div className="container">
                <div className="row">
                    <div onClick={() => setIsPress(!isPress)} className="col-12 textContainer">
                        <h3>{props.title}</h3>
                        { isPress ?
                        <span className="material-icons">expand_less</span> :
                        <span className="material-icons">expand_more</span>
                        }
                    </div>
                </div>
                { isPress &&
                <ul className="all-project-types">
                {                       
                    props.project_types?.map((project, index) => 
                    {                                                       
                        return (
                            <li key={index}>
                                <a href={`/near-me/${project.name.replace(/\s/g,'-').replace(/,/g,'').toLowerCase()}`}>{project.name.toLowerCase() + ' near me'}</a>        
                            </li>
                        )
                    })
                }
                </ul>
                }
            </div>
        </section>
    )
}

export default ProjectTypesList