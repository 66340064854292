import React from "react";
import Modal from 'react-modal';
import QuoteRequestModalHomeLibrary from "../components/quote-request-modal-home-library"
import QuoteStepsModal from '../components/quote-steps-modal'

export default class WhatService extends React.Component {

    
    constructor(props) {
        super(props);
        this.services = [
            {
                title: 'Temporary Dumpster Rental',
                code: 'dumpster',
                ondeffCode: 'dumpsterQuoteRequest',
                image:
                {
                    alt: 'Dumpster Rental',
                    path: '/images/homepage/dumpster-rental.webp'
                }
            },
            {
                title: 'Full Service Junk Removal',
                code: 'junk-removal',
                ondeffCode: 'junkRemovalQuoteRequest',
                image:
                {
                    alt: 'Junk Removal',
                    path: '/images/homepage/junk-removal.webp'
                }
            },
            {
                title: 'Permanent Commercial Dumpsters',
                code: 'comercial-trash-service',
                image:
                {
                    alt: 'Commercial Dumpsters',
                    path: '/images/homepage/comercial-trash-service.webp'
                }
            }
        ]
        this.state =
        {
            showModal: false
        }
    }

    changeShowModal = () => {
        this.setState({showModal: !this.state.showModal})
    }

    render() {
        return (
            <div className="what-service-container">
                <div class="container what-service">
                    <div className="articles">
                        <div className="row m-0" style={{gap: '20px'}}>
                            <div className="col-12">
                                <h2>What Service Do You Need?</h2>
                            </div>
                            {
                                this.services.map(service => {
                                    if (service.code == "comercial-trash-service") {
                                        return (
                                            <div className="articleContainer" style={{backgroundImage: `url(${service.image.path})`}} data-keygroup={service.code}>
                                                <a href="#" className="article " data-keygroup={service.code}  onClick={this.changeShowModal}>
                                                    <div className="text">
                                                        <span data-keygroup={service.code}>{service.title}</span>
                                                    </div>
                                                </a>
                                                
                                                <Modal
                                                    id={1}
                                                    isOpen={this.state.showModal}
                                                    style={{
                                                        content:
                                                        {
                                                            top: '50%',
                                                            left: '50%',
                                                            right: 'auto',
                                                            bottom: 'auto',
                                                            borderRadius: '10px',
                                                            border: 'none',
                                                            backgroundColor: '#e8e8e8',
                                                            marginTop: 'auto',
                                                            marginBottom: 'auto',
                                                            marginRight: '-50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            padding: '0px 20px',
                                                            width: '600px',
                                                        }
                                                    }}
                                                    contentLabel="Get a Quote!"
                                                    shouldCloseOnOverlayClick={false}
                                                    preventScroll={true}
                                                    ariaHideApp={false}
                                                >
                                                <QuoteStepsModal  env={this.props.env} closeModal={this.changeShowModal} />
                                                    </Modal>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <QuoteRequestModalHomeLibrary
                                                service={service}
                                                startVisible={false}
                                            />
                                        )
                                    }

                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};