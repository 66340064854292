import React, { useEffect, useState, lazy, Suspense } from "react";
import Modal from 'react-modal';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
const OnDeffImplementation = lazy(() => import('./on-deff-implementation'))

const QuoteRequestModalHomeLibrary = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)

    let initialValuePoolLimit = 0;
    if (typeof window !== 'undefined') {
        const params = new URLSearchParams(window.location.search)
        if (params.get("test") == "true") {
            initialValuePoolLimit = 1;
        }
    }
    const [limitPoolSize, setPoolTest] = useState(initialValuePoolLimit)

    const changeShowModal = () => {
        setShowModal(!showModal)
    }
   const [modalStyles, setModalStyles] = useState(
        {
            content:
            {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                borderRadius: '10px',
                border: 'none',
                backgroundColor: '#e8e8e8',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                padding: '0px 20px',
                minHeight: 'auto',
                width: '600px'
            }
        })

    return (
        <>
            <div className="articleContainer" style={{backgroundImage: `url(${props.service.image.path})`}}>
                <div className="article" style={{ cursor: 'pointer' }} onClick={changeShowModal}>
                    
                    <div className="text"><span>{props.service.title}</span></div>
                </div>
            </div>
            <Modal
                id={1}
                isOpen={showModal}
                style={modalStyles}
                contentLabel="Get a Quote!"
                shouldCloseOnOverlayClick={false}
                preventScroll={true}
                ariaHideApp={false}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="modal-header">
                    <button type="button" className="close closermodal" onClick={changeShowModal} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div id="quote-steps-modal-library">
                  <Suspense fallback={<h1>Loading…</h1>}>
                    <OnDeffImplementation
                        initialData={[{key: 'limit_poolsize', value: limitPoolSize }]}
                        formCode={props.service.ondeffCode}
                        loadingFunction={setLoading}>
                    </OnDeffImplementation>
                  </Suspense>
                </div>
                <div id="temp"></div>
            </Modal></>
    )
}

export default QuoteRequestModalHomeLibrary;